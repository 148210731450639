<template>
  <div class="">
    <main>
      <slot />
    </main>

    <footer class="bg-baby-powder text-center py-[17px] lg:py-[28px]">
      <p
        class="font-NeueHaasUnicaPro font-bold -tracking-[0.02em] leading-[18px]"
        >Giving Circles is a social network built on<br class="lg:hidden" />
        collective impact, created by
        <a href="https://dollardonationclub.com" target="_blank" rel="noreferrer noopener" class="border-b border-black"
          >Dollar Donation Club</a
        ></p
      >
    </footer>
  </div>
</template>
